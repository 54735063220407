export function messageBox(that,massage,title,callback){    //提示框
    if(!title){
        title = '提示'
    }
    that.$alert(massage, title, {
        confirmButtonText: '确定',
        callback: action => {
            if(callback){
                callback()
            }
        }
    })
}

export function confirmBox(that,massage,title,callback){    //确认框
    if(!title){
        title = '操作提示'
    }
    that.$confirm(massage,title,{type: 'warning',dangerouslyUseHTMLString: true})
          .then(_ => {
            if(callback){
                callback()
            }
          })
          .catch(_ => {});
}

export function dictMapTrans(value,dictGroup){
	let returnValue = value
	for(var i=0;i<dictGroup.length;i++){
		if(value == dictGroup[i].dictKey){
			returnValue = dictGroup[i].dictValue;
			break;
        }
	}
    return returnValue;
}

export function testTimeJudge3Month(value){
	//过期时间倒数三个月
	var time1 = new Date(get3MonthBefore(value));
	//现在
	var time2 = new Date();
	if(time1<time2){
		return "<span style='color:#f00'>" + value + "<span>";
		return value;
	}
	return value;
}

function get3MonthBefore(inputDate){
	var resultDate,year,month,date,hms;
	var currDate = new Date(inputDate);
	year = currDate.getFullYear();
	month = currDate.getMonth()+1;
	date = currDate.getDate();
	hms = currDate.getHours() + ':' + currDate.getMinutes() + ':' + (currDate.getSeconds() < 10 ? '0'+currDate.getSeconds() : currDate.getSeconds());
	switch(month)
	{
		case 1:
		case 2:
		case 3:
			month += 9;
			year--;
			break;
		default:
			month -= 3;
			break;
	}
	month = (month < 10) ? ('0' + month) : month;
	resultDate = year + '-'+month+'-'+date+' ' + hms;
	return resultDate;
}